import '@/directive/chartResize';
import { fitChartSize } from '@/utils/dataUtil.js';
import { Carousel, CarouselItem } from 'element-ui';
import Vue from 'vue';
import App from './App.vue';
import echartsComponent from './components/echarts';
import router from './router';
import store from './store';
import './utils/date.js';
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(echartsComponent)
Vue.config.productionTip = false
Vue.prototype.fitChartSize = fitChartSize;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
