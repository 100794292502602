import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'defaultLink',
    component: () => import('../views/index.vue'),
    redirect: '/linzhou',
    children: [
      {
        path: '/linzhou',
        name: 'homeLink',
        component: () => import('../views/home.vue'),
        meta: {
          requireLogin: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach(async (to, from, next) => {
  next()
})

export default router



