<template>
  <div class="v-charts">
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  data() {
    return {
      myChart: null
    }
  },
  props: {
    option: {
      type: Object,
      default() {
        return {
          title: {
            text: '请设置echarts 显示参数'
          }
        }
      }
    }
  },
  computed: {},
  watch: {
    option: function () {
      this.setChartOption(this.option)
      this.resize()
    }
  },
  methods: {
    initCharts() {
      var chartDom = this.$el
      // 基于准备好的dom，初始化echarts实例
      this.myChart = echarts.init(chartDom)
      this.resize()
    },
    setChartOption(option) {
      // 绘制图表
      if (option == null || option == []) {
        return false
      }
      this.myChart.setOption(option)
    },
    resize() {
      this.$nextTick(() => {
        this.myChart.resize()
      })
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.initCharts()
      this.setChartOption(this.option)
      //容器改变时重新绘制
      window.onresize = function () {
        this.resize()
      }.bind(this)
    })
  }
}
</script>
<style>
.v-charts {
  height: 100%;
  width: 100%;
}
</style>
